import React, { useEffect, useState } from 'react';

import { VariableSchema } from '../../../../../api';
import SbMenu from '../../../../simple-bot/components/common/SbMenu';
import VariableEditor from '../VariableEditor';
import VariablesSettings from '../VariablesSettings';
import { IScenarioEntity } from '../../types';

interface IVariableSelectorProps {
  variables: VariableSchema[];
  onChange?: (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => void;
  onSettingsModalOpen?: () => void;
  onClose?: () => void;
  getVariableUsages: (variable: VariableSchema) => Promise<IScenarioEntity[]>;
}

const VariableSelector: React.FC<IVariableSelectorProps> = ({
  variables,
  onChange = () => {},
  onSettingsModalOpen = () => {},
  onClose = () => {},
  getVariableUsages,
}) => {
  const [editorVisible, setEditorVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [currentVariables, setCurrentVariables] = useState<VariableSchema[]>(variables);

  const onSelectClick = (variable: VariableSchema) => () => {
    const newVariables = undefined;
    onChange(newVariables, variable);
    onClose();
  };

  const onCreateClick = () => {
    setEditorVisible(true);
    onSettingsModalOpen();
  };

  const onSettingsClick = () => {
    setSettingsVisible(true);
    onSettingsModalOpen();
  };

  const onEditorChange = (variable: VariableSchema) => {
    const newVariables = [...currentVariables, variable];
    onChange(newVariables, variable);
    onClose();
  };

  const onEditorClose = () => {
    setEditorVisible(false);
    onClose();
  };

  const onSettingsClose = () => {
    setSettingsVisible(false);
    onClose();
  };

  const onVariablesPropChange = () => setCurrentVariables(variables);
  useEffect(onVariablesPropChange, [variables]);

  return (
    <React.Fragment>
      <SbMenu selectable={false}>
        <SbMenu.ItemGroup title="Выберите переменную:">
          {(variables || []).map((variable) => (
            <SbMenu.Item key={`variable-${variable.id}`} onClick={onSelectClick(variable)}>
              {variable.name}
            </SbMenu.Item>
          ))}
          <SbMenu.Item key="variables_create" sbType="link" onClick={onCreateClick}>
            + Создать новую переменную
          </SbMenu.Item>
        </SbMenu.ItemGroup>
        <SbMenu.Divider />
        <SbMenu.Item key="variables-settings" onClick={onSettingsClick}>
          Настроить&nbsp;переменные
        </SbMenu.Item>
      </SbMenu>
      <VariableEditor
        variables={currentVariables}
        visible={editorVisible}
        onChange={onEditorChange}
        onClose={onEditorClose}
      />
      <VariablesSettings
        getVariableUsages={getVariableUsages}
        variables={variables}
        visible={settingsVisible}
        onChange={onChange}
        onClose={onSettingsClose}
      />
    </React.Fragment>
  );
};

export default VariableSelector;
